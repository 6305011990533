

import axios from "axios";

const client = axios.create({
  //   baseURL: "https://pure-web-apis.petech.ae/",
  baseURL: process.env.REACT_APP_PURE_WEB_API_BASE_URL,
});

client.interceptors.request.use(
  async (config) => {
    config.headers.Accept = "application/json";
    config.headers["X-Website-Domain"] =
      process.env.REACT_APP_PUBLIC_SITE_DOMAIN;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const endPoint = "website/blogs";

export const getAllBlogs = async ({ search = "", categoryId = "" }) => {
  return await client.get(
    `${endPoint}?search=${search}&categoryId=${categoryId}`
  );
};

export const getBlogDetailsBySlug = async ({ slug }) => {
  return await client.get(`${endPoint}/${slug}`);
};

export const postComment = async ({ payload }) => {
  return await client.post("blogs/comments", payload);
};

