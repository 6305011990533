import React from 'react'
import './style.css';

const BlogDetailsShimer = () => {
  return (
    <div className="blog-detail-container">
      <div className="blog-detail-content">
        <div className="shimmer-image"></div>
        <div className="shimmer-title"></div>
        <div className="shimmer-date"></div>
        <div className="shimmer-content"></div>
        <div className="shimmer-content"></div>
      </div>
      <div className="blog-detail-sidebar">
        <div className="shimmer-sidebar"></div>
        <div className="shimmer-sidebar"></div>
        <div className="shimmer-sidebar"></div>
      </div>
    </div>
  );
}

export default BlogDetailsShimer