import React, { useEffect, useRef, useState } from "react";
import "./BlogDetailComp.css";
import { errorRequestHandel, formatDate } from "../../utils/helperFile";
import { useDispatch, useSelector } from "react-redux";
import { _getAllBlogs } from "../../redux/actions/blogs";
import { getAllBlogs, postComment } from "../../https/blogRequests";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const BlogDetailComp = ({ blogDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { blogs } = useSelector(({ _blogs }) => _blogs);

  // Comment states
  const [newComment, setNewComment] = useState("");
  const [commentAddingLoader, setCommentAddingLoader] = useState(false);
  const [newName, setNewName] = useState("");
  const [allComments, setAllComments] = useState(blogDetails?.comments || []);
  const [commentError, setCommentError] = useState("");
  const [submitError, setSubmitError] = useState("");

  const blogIntroRef = useRef(null);
  const blogBodyRef = useRef(null);

  const handleAddComment = async (e) => {
    e.preventDefault();
    setCommentError("");
    if (!newName.trim() || !newComment.trim()) {
      setCommentError("Both name and comment are required.");
      return;
    }
    try {
      setCommentAddingLoader(true);
      const payload = {
        blogSlug: blogDetails?.slug,
        parentId: null,
        content: newComment,
        name: newName,
      };
      const response = await postComment({ payload: payload });
      if (response.status === 200) {
        const newComment = response.data.data;
        setAllComments((prevComments) => [newComment, ...prevComments]);
        setNewComment("");
        setNewName("");
        setCommentAddingLoader(true);
        setSubmitError("");
      }
    } catch (error) {
      setSubmitError(
        "There was an error while submitting your comment. Please try again later."
      );
    }
  };

  const fetchAllBlogs = async () => {
    try {
      const response = await getAllBlogs({
        search: "",
        categoryId: "",
      });

      if (response.status === 200) {
        dispatch(_getAllBlogs(response.data.data));
      }
    } catch (error) {
      errorRequestHandel({ error: error });
    }
  };

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  useEffect(() => {
    if (blogIntroRef.current) {
      // Remove the font-family inline styles from the HTML content
      const elements = blogIntroRef.current.querySelectorAll("*");
      elements.forEach((element) => {
        element.style.fontFamily = '"Montserrat", sans-serif';
      });
    }
    if (blogBodyRef.current) {
      // Remove the font-family inline styles from the HTML content
      const elements = blogBodyRef.current.querySelectorAll("*");
      elements.forEach((element) => {
        element.style.fontFamily = '"Montserrat", sans-serif';
      });
    }
  }, [blogDetails]);

  return (
    <div className="blog-detail-container">
      {/* Main Blog Content */}
      <div className="blog-detail-content">
        <img
          src={
            blogDetails?.gallery.find((gallery) => gallery.isCover)?.file ||
            blogDetails?.gallery[0]?.file
          }
          alt={
            blogDetails?.gallery.find((gallery) => gallery.isCover)?.alt ||
            blogDetails?.gallery[0]?.alt
          }
          className="blog-detail-image"
        />
        <h1 className="blog-detail-title">{blogDetails?.title}</h1>
        <p className="blog-detail-date">
          Published on: {formatDate(blogDetails?.createdAt)}
        </p>
        <div
          className="blog-detail-description"
          ref={blogIntroRef}
          dangerouslySetInnerHTML={{ __html: blogDetails?.introduction }}
        ></div>
        <div
          className="blog-detail-description"
          ref={blogBodyRef}
          dangerouslySetInnerHTML={{ __html: blogDetails?.mainBody }}
        ></div>

        {/* Comments Section */}
        <div className="comments-section">
          <h3>Comments ({allComments.length})</h3>
          <ul className="comments-list">
            {allComments &&
              allComments.length > 0 &&
              allComments.map((comment, index) => (
                <li key={index} className="comment">
                  <p className="comment-name">{comment.name}</p>
                  <p className="comment-date">
                    {formatDate(comment.createdAt)}
                  </p>
                  <p>{comment.content}</p>
                </li>
              ))}
          </ul>

          {/* Add Comment Form */}
          {/* Error Handling Messages */}
          {commentError && <p className="error-message">{commentError}</p>}
          {submitError && <p className="error-message">{submitError}</p>}
          <form className="add-comment-form" onSubmit={handleAddComment}>
            <h4>Add Comment</h4>
            <input
              type="text"
              placeholder="Your Name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              required
            />
            <textarea
              placeholder="Write Your Comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              required
            ></textarea>
            {/* <button type="submit">
              {commentAddingLoader ? "Posting..." : "Add"}
            </button> */}
            <Button variant="primary" className="blog-detail-btn" type="submit">
              {commentAddingLoader ? "Posting..." : "Add"}
            </Button>
          </form>
        </div>
      </div>

      {/* Sidebar */}
      <aside className="blog-detail-sidebar">
        {/* {blogDetails?.categories.length > 0 && (
          <div className="sidebar-section">
            <h3>Categories</h3>
            <ul>
              {blogDetails?.categories.length > 0 &&
                blogDetails?.categories.map((category, index) => (
                  <li key={index}>
                    {category?.title}
                  </li>
                ))}
            </ul>
          </div>
        )} */}
        <div className="sidebar-section">
          <h3>Recent Posts</h3>
          <ul>
            {blogs
              .filter((blog) => blog.slug !== blogDetails.slug)
              .map((blog) => (
                <li
                  key={blog.slug}
                  onClick={() => navigate(`/blog/${blog.slug}`)}
                  className="blog-detail-recent-post"
                >
                  <Link to={`/blog/${blog.slug}`}>{blog.title}</Link>
                  <p className="blog-detail-date">
                    {formatDate(blog.createdAt)}
                  </p>
                </li>
              ))}
          </ul>
        </div>
        {/* <div className="sidebar-section">
          <h3>Tags</h3>
          <div className="tags">
            {blogDetails?.tags.map((tag) => (
              <span key={tag} className="tag">
                {tag}
              </span>
            ))}
          </div>
        </div> */}
      </aside>
    </div>
  );
};

export default BlogDetailComp;
