import React, { useEffect, useState } from "react";
import "./BlogList.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../../https/blogRequests";
import { _getAllBlogs } from "../../redux/actions/blogs";
import { errorRequestHandel, formatDate } from "../../utils/helperFile";
import BlogListShimmer from "../shimer/blog/blog-list-shimmer/BlogListShimmer";
import { Button } from "react-bootstrap";

const BlogList = () => {
  const { blogs } = useSelector((state) => state._blogs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(true);

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  const fetchAllBlogs = async () => {
    try {
      setIsLoadingMore(true);
      const response = await getAllBlogs({
        search: "",
        categoryId: "",
      });

      if (response.status === 200) {
        dispatch(_getAllBlogs(response.data.data));
        setLoading(false);
        setIsLoadingMore(false);
      }
    } catch (error) {
      setLoading(false);
      setIsLoadingMore(false);
      errorRequestHandel({ error: error });
    }
  };

  return (
    <section className="blogs">
      <div className="container">
        {loading ? (
          <BlogListShimmer />
        ) : (
          blogs?.map((blog) => (
            <div
              className="blog-card"
              key={blog.slug}
              onClick={() => navigate(`/blog/${blog.slug}`)}
            >
              <div className="image-box">
                <img
                  src={
                    blog.gallery.find((gallery) => gallery.isCover)?.file ||
                    blog?.gallery[0]?.file
                  }
                  alt={
                    blog.gallery.find((gallery) => gallery.isCover)?.alt ||
                    blog?.gallery[0]?.alt
                  }
                />
              </div>
              <div className="blog-content">
                <p className="blog-date">{formatDate(blog.createdAt)}</p>
                <h3 className="blog-title">{blog.title}</h3>

                <Button variant="primary" className="read-more">
                  Read More
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default BlogList;
