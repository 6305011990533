import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SuperMaster from "../layouts/SuperMaster";
import BlogDetailComp from "../components/blog/BlogDetailComp";
import { getBlogDetailsBySlug } from "../https/blogRequests";
import { errorRequestHandel } from "../utils/helperFile";
import BlogDetailsShimer from "../components/shimer/blog/blog-details-shimer/BlogDetailsShimer";
import Breadcrumb from "../components/blog/BlogBreadCrumbs";

const BlogDetail = () => {
  const params = useParams();
  const { slug } = params;
  const [blogDetails, setBlogDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [steps, setSteps] = useState([])
  const [currentStep, setCurrentStep] = useState("");
  useEffect(() => {
    if (slug) {
      setLoading(true);
      fetchSingleBlogDetails();
    } else {
      setError("slug is missing or invalid");
    }
  }, [slug]);

  const fetchSingleBlogDetails = async () => {
    try {
      const response = await getBlogDetailsBySlug({ slug });
      if (response.status === 200) {
        let _blogDetails = response.data?.data;
        setBlogDetails(_blogDetails);
        // setSteps(["Home", "Blog", _blogDetails.title]);
        setSteps(["Home", "Blog", "Blog Details"]);
        setCurrentStep(_blogDetails.title);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      errorRequestHandel({ error: error });
    }
  };

  if (loading) {
    return (
      <SuperMaster>
        <BlogDetailsShimer />
      </SuperMaster>
    ); 
  }

  if (error) {
    return (
      <SuperMaster>
        <p>{error}</p>
      </SuperMaster>
    );
  }

  return (
    <SuperMaster>
      <Breadcrumb steps={steps} currentStep={currentStep} />
      <BlogDetailComp blogDetails={blogDetails} />
    </SuperMaster>
  );
};

export default BlogDetail;
