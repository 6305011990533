export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const SINGLE_BLOG_DETAIL = "SINGLE_BLOG_DETAIL";

export const _getAllBlogs = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_BLOGS,
      payload: data,
    });
  };
};

export const _getSingleBlogDetail = (data) => {
  return (dispath) => {
    dispath({
      type: SINGLE_BLOG_DETAIL,
      payload: data,
    });
  };
};
