import React from "react";
import "./BlogBreadCrumbs.css";
import { Link, useNavigate } from "react-router-dom";

const Breadcrumb = ({ steps, currentStep }) => {
  const navigate = useNavigate();
  console.log("steps",steps, "current step", currentStep);
  return (
    <nav className="breadcrumb">
      {steps.map((step, index) => (
        <span key={index} className="breadcrumb_item">
          <span
            className={`breadcrumb_text ${
              currentStep === step ? "current_step" : ""
            }`}
          >
            <div
              className="breadcrumb_step"
              onClick={() => {
                if (index < steps.length - 1) {
                  if (index === 0) {
                    navigate(`/`);
                  } else if (index === 1) {
                    navigate(`/blog`);
                  }
                }
              }}
            >
              {step}
            </div>
          </span>
          {index < steps.length - 1 && (
            <span className="breadcrumb_separator">{">"}</span>
          )}
        </span>
      ))}
    </nav>
  );
};

export default Breadcrumb;
