import React from 'react'
import SuperMaster from '../layouts/SuperMaster'
import BlogList from '../components/blog/BlogList'
import Breadcrumb from '../components/blog/BlogBreadCrumbs';

const Blogs = () => {
  const steps = ["Home", "Blog"];
  const currentStep = "Blog";
  return (
    <>
      <SuperMaster>
        <Breadcrumb steps={steps} currentStep={currentStep} />
        <BlogList />
      </SuperMaster>
    </>
  );
}

export default Blogs