import React from 'react'
import { Shimmer } from 'react-shimmer';
import "./style.css";

const BlogListShimmer = () => {
  return (
    <>
      {Array(4)
        .fill(0)
        .map((_, index) => (
          <div className="blog-card" key={index}>
            <Shimmer width="100%" height="200px" />
            <div className="blog-content">
              <Shimmer
                width="100%"
                height="20px"
                style={{ marginBottom: "10px" }}
              />
              <Shimmer
                width="80%"
                height="20px"
                style={{ marginBottom: "10px" }}
              />
              <Shimmer width="50%" height="20px" />
            </div>
          </div>
        ))}
    </>
  );
}

export default BlogListShimmer